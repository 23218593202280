import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosCheckmarkCircle } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | "Смак України: Майстерність Традиційної Кухні"
			</title>
			<meta name={"description"} content={"Кулінарна подорож в світ смаку та ароматів Української кухні! "} />
			<meta property={"og:title"} content={"Home | \"Смак України: Майстерність Традиційної Кухні\""} />
			<meta property={"og:description"} content={"Кулінарна подорож в світ смаку та ароматів Української кухні! "} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/side-view-traditional-russian-ukrainian-red-soup-borscht-with-beef-vegetables-beet-potato-carrot-cabbage-onion-fresh-herbs-spices-clay-bowl-bla_140725-12215%20%281%29.jpg?v=2023-10-06T15:15:23.438Z) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="normal 900 82px/1.2 --fontFamily-mono"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Смак України
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--headline3"
					lg-text-align="center"
					text-align="center"
				>
					Майстерність Традиційної Кухні
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Взяти участь
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				padding="80px 72px 80px 72px"
				background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/Image%20%289%29.png?v=2021-08-26T14:56:08.732Z) 0% 0% /cover no-repeat scroll padding-box"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				sm-padding="60px 36px 60px 36px"
			>
				<Box
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Ласкаво запрошуємо вас на кулінарну подорож в світ смаку та ароматів Української кухні!
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sans" color="--greyD3" md-text-align="center">
						На цих кулінарних курсах ми відкриваємо двері у світ аутентичних смаків та кулінарних традицій України. Тут ви дізнаєтеся секрети приготування найпопулярніших страв, які вже стали класикою: борщ з пампушками, бограч, банош, вареники, галушки та багато інших. Наші досвідчені кулінарні майстри навчать вас не лише приготовлювати ці страви, але й розкажуть історію та традиції, які пов'язані з кожною з них. Приєднуйтеся до нас, щоб доторкнутися до смаку України та здивувати своїх близьких справжніми українськими смаками!
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 0 0" quarkly-title="Content-6">
			<Override
				slot="SectionContent"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				width="100%"
				min-width="100%"
				lg-grid-template-columns="1fr"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/top-view-stewed-beef-meat-with-potatoes-green-pappers-herbs-clay-bowl-wooden-table_140725-11574.jpg?v=2023-10-06T15:20:35.914Z) 50% 50% /cover repeat scroll padding-box"
				lg-height="600px"
				md-height="500px"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				flex-direction="column"
				padding="80px 60px 80px 60px"
				sm-padding="50px 25px 80px 25px"
			>
				<Text margin="0px 0px 10px 0px" font="normal 400 18px/1.5 --fontFamily-sans" color="#5d20a9">
					Смак України
				</Text>
				<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
					Майстерність{" "}
					<br />
					Традиційної Кухні
				</Text>
				<Text margin="0px 0px 25px 0px" font="normal 300 22px/1.5 --fontFamily-sans" color="#505257">
					Кулінарні курси "Смак України: Майстерність Традиційної Кухні" відкриті для всіх, навіть для людей без жодного кулінарного досвіду.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="#5d20a9"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						Наша мета - зробити українську кухню доступною та зрозумілою для всіх.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="#5d20a9"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						Ми надаємо детальні інструкції, проводимо практичні заняття та ділимося секретами, що дозволять навіть початківцям опанувати це мистецтво.{" "}
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="#5d20a9"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						Ви покинете наші курси з впевненістю у власних кулінарних здібностях та готовністю приготувати аутентичні українські страви вдома.
					</Text>
				</Box>
				<Box text-align="center">
					<Link
						href="/contacts"
						padding="12px 24px 12px 24px"
						color="--dark"
						background="--color-secondary"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 0px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-orange"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						hover-transition="background-color 0.2s ease-in-out 0s"
						max-width="200px"
						text-align="center"
					>
						Записатися
					</Link>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65201646cd81ee001f7ce215"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});